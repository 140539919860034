/**
 * @generated SignedSource<<ecc731beb0b2426e9e8b7f1581c69a96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderContent_ProductAppsFragment$data = {
  readonly id: string;
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceAdminDropdownFragment" | "LearnModeSwitchFragment">;
  readonly " $fragmentType": "HeaderContent_ProductAppsFragment";
};
export type HeaderContent_ProductAppsFragment$key = {
  readonly " $data"?: HeaderContent_ProductAppsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderContent_ProductAppsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderContent_ProductAppsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExperienceAdminDropdownFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LearnModeSwitchFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "78a9c70a4a74184577e30efd17a4b88a";

export default node;
