import { JoinCommunityPageParams } from "@/authentication/join-community/JoinCommunityPage"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { GlobalDrawerParams, useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ArrowLeftIcon from "@/core/ui/iconsax/linear/arrow-left-1.svg"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import MembershipPlanDetailsCard, {
  MembershipPlanDetailsCardSkeleton,
} from "@/membership-plan/register/MembershipPlanDetailsCard"
import RegistrationMembershipPlanDetails from "@/membership-plan/register/RegistrationMembershipPlanDetails"
import { MembershipPlanDetailsCardFragment$data } from "@/membership-plan/register/__generated__/MembershipPlanDetailsCardFragment.graphql"
import { MembershipPlanSelectBodyQuery } from "@/membership-plan/register/__generated__/MembershipPlanSelectBodyQuery.graphql"
import MembershipPlanRegistration from "@/product/register/MembershipPlanRegistration"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  DiscoAlert,
  DiscoButton,
  DiscoIconButton,
  DiscoModalProps,
  DiscoText,
  DiscoTextSkeleton,
} from "@disco-ui"
import DiscoImage from "@disco-ui/image/DiscoImage"
import { useTheme } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import { setSearchParams, useQueryParams } from "@utils/url/urlUtils"
import { range } from "lodash"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

export type MembershipPlanSelectBodyProps = TestIDProps & {
  experienceId?: GlobalID | null
  selectedMembershipId?: GlobalID | null
  step?: "select" | "registration" | "success"
  onClose?: DiscoModalProps["onClose"]
}

export type MembershipPlanRegistrationSteps = "select" | "registration" | "success"

function MembershipPlanSelectBody({
  testid = "MembershipPlanSelectBody",
  experienceId,
  selectedMembershipId,
  step: initialStep = "select",
  onClose,
}: MembershipPlanSelectBodyProps) {
  const activeOrganization = useActiveOrganization()!
  const classes = useStyles()
  const drawer = useGlobalDrawer("registration")
  const { email, inviteToken } = useQueryParams<JoinCommunityPageParams>()
  const modal = useInitUnsavedChangesModalContext()
  const experienceLabel = useLabel("experience")
  const memberLabel = useLabel("admin_member")
  const history = useHistory()
  const hasManagePermission = usePermissions(activeOrganization)?.has("products.manage")
  const params = useQueryParams<{ registrationStep?: MembershipPlanRegistrationSteps }>()
  const [step, setStep] = useState<MembershipPlanRegistrationSteps>(
    selectedMembershipId ? "registration" : initialStep
  )
  const [showUnpublishedAlert, setShowUnpublishedAlert] = useState(false)
  const [selectedPlanId, setSelectedPlanId] = useState(selectedMembershipId)
  const { viewerMembershipPlan, viewerMembership } = activeOrganization
  const isSwitchingPlans = Boolean(viewerMembershipPlan && viewerMembership)

  const disablePlans =
    activeOrganization?.visibility === "private" && !activeOrganization.viewerMembership

  // Find the membership plans the product is included in
  const { product, organization } = useLazyLoadQuery<MembershipPlanSelectBodyQuery>(
    graphql`
      query MembershipPlanSelectBodyQuery(
        $productId: ID!
        $organizationId: ID!
        $hideDrafts: Boolean!
        $hideNonPublic: Boolean!
        $getAllPlans: Boolean!
      ) {
        product: node(id: $productId) {
          ... on Product {
            id
            includedInBenefits(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic) {
              edges {
                node {
                  id
                  membershipPlan {
                    id
                    name
                    cover
                    status
                    ...MembershipPlanDetailsCardFragment
                      @arguments(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic)
                    ...RegistrationMembershipPlanDetailsFragment
                      @arguments(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic)
                    ...MembershipPlanRegistrationFragment
                  }
                  ...usePermissionsFragment
                }
              }
            }
          }
        }
        organization: node(id: $organizationId) {
          ... on Organization {
            id
            membershipPlans: products(
              type: "membership_plan"
              hideDrafts: $hideDrafts
              hideNonPublic: $hideNonPublic
            ) @include(if: $getAllPlans) {
              edges {
                node {
                  id
                  name
                  cover
                  status
                  ...MembershipPlanDetailsCardFragment
                    @arguments(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic)
                  ...RegistrationMembershipPlanDetailsFragment
                    @arguments(hideDrafts: $hideDrafts, hideNonPublic: $hideNonPublic)
                  ...MembershipPlanRegistrationFragment
                  ...usePermissionsFragment
                }
              }
            }
          }
        }
      }
    `,
    {
      productId: experienceId || "",
      organizationId: activeOrganization.id,
      hideDrafts: !hasManagePermission,
      hideNonPublic: !hasManagePermission,
      getAllPlans: !experienceId,
    }
  )

  useEffect(() => {
    if (step === "registration")
      setShowUnpublishedAlert(selectedMembershipPlan()?.status !== "published")
    else setShowUnpublishedAlert(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, product])

  const availableBenefits = Relay.connectionToArray(product?.includedInBenefits)
  const membershipPlans = experienceId
    ? availableBenefits.map((ab) => ab.membershipPlan)
    : Relay.connectionToArray(organization?.membershipPlans)

  return (
    <div className={classes.root}>
      {/* Header */}
      {hasManagePermission && showUnpublishedAlert && (
        <DiscoAlert
          data-testid={"MembershipPlanSelectBody.unpublished-alert"}
          severity={"warning"}
          marginBottom={2}
          message={`This plan is not published and cannot be selected or viewed by ${memberLabel.plural}.`}
        />
      )}

      <div className={classes.headerRow}>
        {step === "registration" && params.registrationStep !== "success" && (
          <>
            <DiscoIconButton onClick={onBack} className={classes.backButton}>
              <ArrowLeftIcon />
            </DiscoIconButton>

            <div>
              <DiscoText
                variant={"body-xs-600-uppercase"}
                color={"primary.main"}
                marginBottom={1}
              >
                {isSwitchingPlans ? "Update Plan" : "Join Community"}
              </DiscoText>
              <DiscoText
                data-testid={`MembershipPlanRegistration.title`}
                variant={"heading-md"}
                marginBottom={1}
              >
                {selectedMembershipPlan()?.name}
              </DiscoText>
            </div>
          </>
        )}

        {onClose && (
          <DiscoIconButton
            className={classes.closeIcon}
            testid={`${testid}.close-button`}
            onClick={onClose}
          >
            <CloseIcon />
          </DiscoIconButton>
        )}
      </div>

      {/* Body */}
      {renderStep()}
    </div>
  )

  function renderStep() {
    switch (step) {
      case "select":
        return (
          <>
            <div className={classes.title}>
              <DiscoText
                variant={"body-xs-600-uppercase"}
                color={"primary.main"}
                marginBottom={1}
              >
                {isSwitchingPlans ? "Update Plan" : "Join Community"}
              </DiscoText>
              <DiscoText variant={"heading-md"} marginBottom={1}>
                {"Select a plan that works best for you."}
              </DiscoText>
            </div>
            <div className={classes.membershipPlans}>
              {membershipPlans.map((mp) => (
                <MembershipPlanDetailsCard
                  key={mp.id}
                  membershipPlanKey={mp}
                  onClick={handleSelectPlan}
                  className={classes.card}
                />
              ))}
            </div>
          </>
        )
      case "registration": {
        const plan = selectedMembershipPlan()
        if (!plan) return null
        return (
          <UnsavedChangesModalProvider {...modal}>
            <div className={classes.container}>
              <div className={classes.column}>
                {/* Details */}
                <div className={classes.leftColumn}>
                  <RegistrationMembershipPlanDetails membershipPlanKey={plan} onPage />
                </div>

                {/* Registration */}
                <div className={classes.rightColumn}>
                  <MembershipPlanRegistration membershipPlanKey={plan} onPage />
                </div>
              </div>
            </div>
          </UnsavedChangesModalProvider>
        )
      }
      case "success": {
        const plan = selectedMembershipPlan()
        if (!plan) return null
        return (
          <div className={classes.successContainer}>
            {plan.cover && <DiscoImage src={plan.cover} className={classes.cover} />}

            <DiscoText
              data-testid={`${testid}.success-title`}
              variant={"heading-lg"}
              paddingBottom={1}
              className={classes.successText}
            >
              {"Congratulations!"}
            </DiscoText>

            <DiscoText
              data-testid={`${testid}.success-message`}
              color={"text.secondary"}
              paddingBottom={4}
              className={classes.successText}
            >
              {`You successfully registered for ${plan.name}.`}
            </DiscoText>

            {renderSuccessCta()}
          </div>
        )
      }
      default:
        return null
    }
  }

  function handleSelectPlan(membershipPlan: MembershipPlanDetailsCardFragment$data) {
    if (disablePlans) return

    setStep("registration")
    setSelectedPlanId(membershipPlan.id)
    history.replace({
      ...location,
      search: setSearchParams(location.search, {
        membershipPlanSlug: membershipPlan.slug,
        ...(drawer.params.drawerRegistrationExperienceId && {
          drawerRegistrationExperienceId: drawer.params.drawerRegistrationExperienceId,
        }),
      }),
      state: {
        joinMembershipPlanFirst: Boolean(drawer.params.drawerRegistrationExperienceId),
      },
    })
  }

  function renderSuccessCta() {
    // Redirect to the experience drawer if the user originally registered for an experience
    if (experienceId) {
      return (
        <DiscoButton
          testid={`${testid}.cta-link`}
          to={{
            pathname: ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.UPCOMING,
            search: setSearchParams<
              GlobalDrawerParams<"registration"> & { inviteToken?: string }
            >("", {
              drawerRegistrationExperienceId: experienceId,
              inviteToken,
            }),
          }}
          target={"_blank"}
        >
          {`Continue to ${experienceLabel.singular}`}
        </DiscoButton>
      )
    }

    return (
      <DiscoButton
        testid={`${testid}.cta-link`}
        to={{
          pathname: ROUTE_NAMES.COMMUNITY.HOME.ROOT,
        }}
        target={"_blank"}
      >
        {`Continue to ${activeOrganization.name}`}
      </DiscoButton>
    )
  }

  function onBack() {
    switch (step) {
      case "registration":
        setStep("select")
        // remove membershipPlanSlug query param
        history.replace({
          pathname: location.pathname,
          search: setSearchParams<{
            membershipPlanSlug?: string
            email?: string
            inviteToken?: string
            mpid?: string
          }>(location.search, {
            ...(email && { email }),
            ...(inviteToken && { inviteToken }),
            membershipPlanSlug: undefined,
            mpid: undefined,
          }),
        })
        break
      case "success":
        setStep("registration")
        break
    }
  }

  function selectedMembershipPlan() {
    return membershipPlans.find((mp) => mp.id === selectedPlanId)
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  title: {
    textAlign: "center",
    paddingBottom: theme.spacing(5),
    flex: "auto",
  },
  membershipPlans: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(3),
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    width: "100%",
    maxWidth: theme.measure.page.contentMaxWidth,
    margin: "0 auto",
  },
  column: {
    display: "grid",
    padding: theme.spacing(3),
    gap: theme.spacing(3),
    justifyContent: "center",
    gridTemplateColumns: "minmax(auto, 720px) 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      width: "100%",
      maxWidth: theme.measure.modalMaxWidth.medium,
    },
  },
  leftColumn: {
    width: "100%",
  },
  rightColumn: {
    display: "flex",
    justifyContent: "center",
  },
  successContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, "25%"),
  },
  cover: {
    borderRadius: theme.measure.borderRadius.big,
    marginBottom: theme.spacing(3),
  },
  successText: {
    textAlign: "center",
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2.5),
  },
  closeIcon: {
    marginLeft: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  card: {
    width: "312px",
    minHeight: "525px",
  },
}))

const MembershipPlanSelectBodySkeleton: React.FC = () => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <div style={{ paddingTop: theme.spacing(4) }}>
      <div
        style={{
          paddingBottom: theme.spacing(5),
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DiscoTextSkeleton
          variant={"body-xs-600-uppercase"}
          color={"primary.main"}
          marginBottom={1}
          width={"10%"}
        />
        <DiscoTextSkeleton variant={"heading-md"} marginBottom={1} width={"35%"} />
      </div>
      <div className={classes.membershipPlans}>
        {range(4).map((i) => (
          <MembershipPlanDetailsCardSkeleton key={i} />
        ))}
      </div>
    </div>
  )
}

export default Relay.withSkeleton<MembershipPlanSelectBodyProps>({
  component: observer(MembershipPlanSelectBody),
  skeleton: MembershipPlanSelectBodySkeleton,
})
