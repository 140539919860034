import Badge, { BadgeProps, BadgeSize } from "@/admin/experiences/badges/Badge"
import { useProductsAdminLearnMode } from "@/core/context/ProductsAdminLearnModeContext"
import { ProductBadgeFragment$key } from "@/main/page/side-bar/temporary-sidebar/__generated__/ProductBadgeFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ProductBadgeLearnModeIcon from "./product-badge-learn-mode.svg"

import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface ProductBadgeProps extends TestIDProps {
  productKey: ProductBadgeFragment$key
  size?: BadgeSize
  isSelected?: BadgeProps["isSelected"]
}

export default function ProductBadge({
  testid,
  productKey,
  size,
  isSelected,
}: ProductBadgeProps) {
  const product = useFragment<ProductBadgeFragment$key>(
    graphql`
      fragment ProductBadgeFragment on Product {
        id
        badge {
          ...BadgeFragment
        }
      }
    `,
    productKey
  )

  const { isViewerInLearnModeForProduct } = useProductsAdminLearnMode()
  const isViewerInLearnMode = isViewerInLearnModeForProduct(product.id)

  const classes = useStyles({ isViewerInLearnMode, isSelected: Boolean(isSelected) })

  return (
    <div className={classes.leftIconOuterContainer}>
      <div className={classes.leftIconInnerContainer}>
        <Badge isSelected={isSelected} badgeKey={product.badge} size={size} />
      </div>

      {isViewerInLearnMode && (
        <div
          data-testid={`${testid}.learn-mode-icon`}
          className={classes.learnModeIconContainer}
        >
          <ProductBadgeLearnModeIcon />
        </div>
      )}
    </div>
  )
}

type StyleProps = {
  isViewerInLearnMode: boolean
  isSelected: boolean
}

const useStyles = makeUseStyles((theme) => ({
  leftIconOuterContainer: ({ isViewerInLearnMode, isSelected }: StyleProps) => ({
    border: `1.5px solid ${
      isViewerInLearnMode
        ? isSelected
          ? theme.palette.background.paper
          : theme.palette.primary.main
        : "transparent"
    }`,
    borderRadius: theme.measure.borderRadius.smedium,
    position: "relative",
  }),
  leftIconInnerContainer: ({ isViewerInLearnMode, isSelected }: StyleProps) => ({
    border: `1.5px solid ${
      isViewerInLearnMode
        ? isSelected
          ? theme.palette.primary.main
          : theme.palette.background.paper
        : "transparent"
    }`,
    borderRadius: theme.measure.borderRadius.smedium,
  }),
  learnModeIconContainer: ({ isSelected }: StyleProps) => ({
    position: "absolute",
    top: "2px",
    right: "0px",
    transform: "translate(50%, -50%)",

    width: "17.5px",
    height: "13.5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: isSelected
      ? theme.palette.background.paper
      : theme.palette.primary.main,
    border: `1.5px solid ${
      isSelected ? theme.palette.primary.main : theme.palette.background.paper
    }`,

    "& svg": {
      width: "9px !important",
      height: "8px !important",
      color: `${
        isSelected ? theme.palette.primary.main : theme.palette.background.paper
      } !important`,
    },
  }),
}))
