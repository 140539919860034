import { useLabel } from "@/core/context/LabelsContext"
import CreateInvitationForm from "@/invitation/create/form/CreateInvitationForm"
import { ProductRole } from "@/invitation/create/form/__generated__/CreateInvitationFormMutation.graphql"
import { CreateInvitationModalQuery } from "@/invitation/__generated__/CreateInvitationModalQuery.graphql"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoModal } from "@disco-ui"
import { graphql } from "relay-runtime"

interface CreateInvitationModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  productId?: GlobalID | null
  onlyAdminRoles?: boolean
  defaultProductRole?: ProductRole
  givenEmails?: string[]
}

const CreateInvitationModal: React.FC<CreateInvitationModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  productId = null,
  onlyAdminRoles,
  defaultProductRole,
  givenEmails,
}) => {
  const membersLabel = useLabel("admin_member")

  const { node } = Relay.useSkippableLazyLoadQuery<CreateInvitationModalQuery>(
    graphql`
      query CreateInvitationModalQuery($productId: ID!) {
        node(id: $productId) {
          ... on Product {
            id
            type
            ...CreateInvitationFormFragment
          }
        }
      }
    `,
    {
      productId: productId ?? "",
    },
    { skip: !productId }
  )

  const productLabelSingular = useAdminProductLabel(node?.type ?? "course").singular
  const inviteEntityLabelSingular = productId ? productLabelSingular : "Community"

  const classes = useStyles()

  return (
    <DiscoModal
      title={`Invite to ${inviteEntityLabelSingular}`}
      modalContentLabel={`Invite ${membersLabel.plural}`}
      isOpen={isOpen}
      width={"720px"}
      buttons
      body={
        isOpen && (
          <CreateInvitationForm
            onCancel={onClose}
            onSubmit={onSubmit}
            productId={productId}
            onlyAdminRoles={onlyAdminRoles}
            defaultProductRole={defaultProductRole}
            givenEmails={givenEmails}
            productKey={node}
          />
        )
      }
      testid={"CreateInvitationModal"}
      onClose={onClose}
      classes={{ buttons: classes.buttons }}
    />
  )
}
const useStyles = makeUseStyles({
  buttons: {
    width: "100%",
  },
})

export default Relay.withSkeleton({
  component: CreateInvitationModal,
  skeleton: () => null,
})
